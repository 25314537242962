

















import { Component, Vue } from 'vue-property-decorator'
import SelectBox from '@/components/SelectBox.vue'
import { VaccinationState } from '@/store/modules/walkthrough/types/pre-test/vaccinationState'

@Component({
  components: {
    SelectBox
  }
})
export default class Step2 extends Vue {
  private selected = ''

  private updateVal (ev: string): void {
    this.selected = ev
    this.$store.dispatch('walkthrough/setVaccinationState', this.selected === 'Yes' ? VaccinationState.FullyVaccinated : VaccinationState.NotFullyVaccinated)
  }

  private mounted (): void {
    const previouslySelectedValue = this.$store.getters['walkthrough/vaccinationState']
    const selectedItem = previouslySelectedValue === 1 ? 'Yes' : previouslySelectedValue === 0 ? 'No' : ''
    if (previouslySelectedValue === '') {
      return
    }
    (this.$refs.selectBox as SelectBox).setVal(selectedItem)
    this.selected = selectedItem
  }

  private goTo (): void {
    this.$router.push('3')
  }
}
