















import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
@Component({
  components: {
    ContentArea,
    Title,
    ProgressLine,
    Screen_1: () => import('./Screen_1.vue'),
    Screen_2: () => import('./Screen_2.vue')
  }
})
export default class Step2 extends Vue {
  private nameComponent:string | null = 'Screen_1'

  private mounted (): void {
    this.$root.$on('open-screen', (screenName: string) => {
      this.nameComponent = screenName
    })
    if (this.$route.params.name) this.nameComponent = this.$route.params.name

    if (window.innerWidth < this.$data.mobileWidth) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    switch (this.nameComponent) {
      case 'Screen_2':
        this.nameComponent = 'Screen_1'
        break
      case 'Screen_1':
        this.$router.push('/quidel/1')
        break
    }
  }

  private setScreen (screenName: string): void {
    this.nameComponent = screenName
  }

  private destroyed (): void {
    this.$root.$off('open-screen')
    if (window.innerWidth < this.$data.mobileWidth) this.$root.$off('mobile-go-back')
  }
}
